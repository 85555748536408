<template>
  <div class="talk">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        {{ teach.scheduleName }}
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="item" v-for="(item, idx) in teach.content" :key="idx">
        <div class="sys" v-if="item.unitType == 2">
          <div class="text" v-html="item.content"></div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="left" @click="onClickVideo">
        <div class="up">
          <img v-if="vodParam != null" :src="playVideoImg" />
        </div>
        <div v-if="vodParam != null" class="down">
          本节视频
        </div>
      </div>
      <div class="center" @click="nextTalk()">
        <div class="btn">
          <img :src="talkContinueImg" />
        </div>
      </div>
      <div class="right" @click="onClickShowCsWidget">
        <div class="up">
          <img :src="talkTeacherImg" />
        </div>
        <div class="down">
          联系老师
        </div>
      </div>
    </div>

    <csWidget v-show="showCsWidget" :csId="'1'"></csWidget>
    <videoWidget :vodParam="vodParam" v-if="showVideo"></videoWidget>
  </div>
</template>

<script>
const COS = 0;
const MARKDOWN = 1;
const HTML = 2;
const VOD = 3;
const PRACTICE = 4;

import { TouchEmulator } from "@/util/touch.js";
import { ImagePreview } from "vant";
import csWidget from "@/components/csWidget.vue";
import videoWidget from "@/components/videoWidget.vue";
import { teachApi } from "@/api/teachApi.js";
export default {
  components: { csWidget, videoWidget },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      playVideoImg: require("@/assets/images/video-play.png"),
      talkTeacherImg: require("@/assets/images/talk-teacher.png"),
      talkContinueImg: require("@/assets/images/talk-continue.png"),
      talkLeftImg: require("@/assets/images/talk-left.png"),
      talkRightImg: require("@/assets/images/talk-right.png"),
      courseId: 0,
      scheduleId: 0,
      teachId: 0,
      teach: {
        scheduleName: "",
        content: [],
      },
      vodParam: {},
      showCsWidget: false,
      showVideo: false,
    };
  },
  methods: {
    initPage() {
      this.getParamId();
      this.getTeach();
    },
    getParamId() {
      if (this.$route.query) {
        if (this.$route.query.c) {
          this.courseId = this.$route.query.c;
        }
        if (this.$route.query.s) {
          this.scheduleId = this.$route.query.s;
        }
        if (this.$route.query.t) {
          this.teachId = this.$route.query.t;
        }
      }
    },
    getTeach() {
      teachApi.get(this.courseId, this.scheduleId, this.teachId).then((ret) => {
        if (ret.code == 0 && ret.data) {
          this.teach = ret.data;
          this.vodParam = this.teach.vodParam;
          console.log("vodParam", this.vodParam, this.teach);
          this.$nextTick(() => {
            this.listenOnHideTextClick();
            this.listenOnImageClick();
          });
        }
      });
    },
    nextTalk() {
      this.$nextTick(() => {
        let container = this.$el.querySelector(".main");
        container.scrollTop = container.scrollHeight + 300;
      });
    },
    //有bug
    listenOnImageClick() {
      const imgList = document.querySelectorAll(".md img");
      for (const tmp of imgList) {
        tmp.addEventListener("click", this.imgClick);
      }
    },
    listenOnHideTextClick() {
      const textList = document.querySelectorAll(".hidetext");
      // console.log('...', textList);
      for (const tmp of textList) {
        tmp.addEventListener("click", this.hideTextClick);
      }
    },
    hideTextClick(e) {
      const elem = e.srcElement;
      // console.log('..........', elem, elem.classList);
      if (elem.classList.contains("hidetext")) {
        // 隐藏 => 展示
        elem.classList.remove("hidetext");
        elem.classList.add("redtext");
      } else {
        // 展示 => 隐藏
        elem.classList.remove("redtext");
        elem.classList.add("hidetext");
      }
    },
    imgClick(e) {
      console.log(e);
      const elem = e.srcElement;
      ImagePreview({ images: [elem.currentSrc], closeable: true });
    },
    onGoBack() {
      this.$router.go(-1);
    },
    onClickShowCsWidget() {
      this.showCsWidget = true;
    },
    onClickVideo() {
      if (this.vodParam == null) {
        return;
      }
      this.showVideo = true;
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
    listenOnCloseVideoWidget() {
      this.$EventBus.$on("onCloseVideoWidget", (val) => {
        this.showVideo = false;
      });
    },
  },
  mounted() {
    this.initPage();
    this.listenOnCloseCsWidget();
    this.listenOnCloseVideoWidget();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.talk {
  display: flex;
  flex-direction: column;
  background: rgb(241, 241, 241);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    .item {
      .sys {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .text {
          background: #ffffff;
          border-radius: 6px;
          font-size: 16px;
          color: #323233;
          letter-spacing: 0.36px;
          margin: 0 12px;
          padding: 6px;
          //max-width: 90%;
          width: fit-content;
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
    height: 78px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #f7f7f7;
    border-top: 1px solid #d2d2d2;
    //padding: 20px 0 0 0;
    .left {
      margin: 15px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      width: 86px;
      .up {
        img {
          width: 24px;
          height: auto;
        }
      }
      .down {
        font-size: 10px;
        color: #323233;
        letter-spacing: 0.22px;
      }
    }
    .center {
      margin: 15px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 40px;
        background: #4d52d1;
        border-radius: 12px;
        img {
          width: 21px;
          height: auto;
        }
      }
    }
    .right {
      margin: 15px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 86px;
      .up {
        img {
          width: 24px;
          height: auto;
        }
      }
      .down {
        font-size: 10px;
        color: #323233;
        letter-spacing: 0.22px;
      }
    }
  }
}
</style>

<style lang="less">
img {
  width: 100%;
  height: auto;
}
.md {
  .md-pre {
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 4px;
    background-color: #dddddd;
    padding: 5px;
    margin: 5px 0px;
    box-shadow: 2px 2px 4px #aaaaaa;
  }
  .md-p {
    margin: 0rem 0rem 1.2rem 0rem;
    &:last-child {
      margin: 0rem;
    }
  }
  .md-ul {
    display: block;
    margin: 0 0 15px 0;
    .md-li {
      margin-left: 15px;
      display: block;
      &::before {
        content: "◦";
        margin: 0 10px 0 0px;
        font-size: 1.5rem;
      }
      .md-ul {
        .md-li {
          margin-left: 15px;
          display: block;
          &::before {
            content: "-";
            margin: 0 10px 0 0px;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .hidetext {
    background: #fe611c;
    color: #fe611c;
  }
  .redtext {
    color: red;
  }
}
</style>
